<template>
	<div class="main">
		<nav-bar />
		<!-- Privacy navigation -->
		<nav>
			<div class="sub-menu" v-if="hasMenu">
				<ContentNavigation>
					<NuxtLink v-for="child of children" :key="child._path" :to="child._path">
						{{ setLanguage(child.title) }}
					</NuxtLink>
				</ContentNavigation>
			</div>
		</nav>

		<div class="body">
			<slot />
		</div>

		<footer-bar />
	</div>
</template>

<script setup lang="ts">
//  hardcode import from vue-router #imports has a bug
import { useRoute } from 'vue-router';
import { useHead, useAsyncData } from '#imports';
import { ref, reactive } from 'vue';

const route = useRoute();

// Head
useHead({
	title: 'Outfit7 Neo',
	meta: [
		{ name: 'description', content: 'We make great games for everyone!' },
		{ name: 'theme-color', content: '#333333' },
		{ name: 'apple-mobile-web-app-capable', content: 'yes' },
		{ name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
		{ name: 'og:title', content: 'Outfit7 Neo' },
		{ name: 'og:description', content: 'We make great games for everyone!' },
		// { name: 'og:image', content: 'https://cdn.outfit7.com/web/transfered/HDS.png' },
		{ name: 'og:url', content: 'www.outfit7neo.com' }
	]
});

let hasMenu = ref(false);
let children = reactive([]);

if (route.params.slug) {
	// Extract sub folder for
	let [policy, ccpa] = route.params.slug;

	const { data: navigation } = await useAsyncData('navigation', () => {
		return fetchContentNavigation();
	});

	const filteredNavigation = navigation.value.find(obj => {
		return obj._path === `/${policy}`;
	});

	children = filteredNavigation.children;

	if (children && children.length > 1 && ccpa !== 'ccpa') {
		hasMenu.value = true;
	}
}

const availableLanguages = {
	de: 'Deutsch',
	en: 'English',
	it: 'Italiano',
	es: 'Español',
	fr: 'Français',
	ja: '日本語',
	ko: '한국어',
	pt: 'Português',
	ru: 'Pусский',
	zh: '中文（简体）'
};

function setLanguage(lang) {
	let l = lang.toLowerCase().replace(/\s/g, '');
	let fullLang = availableLanguages[l];
	return fullLang;
}
</script>

<style scoped lang="scss">
@import '~/assets/design';

.main {
	position: relative;
	display: block;
	width: 100%;
	min-width: $size-content-width-min;
	min-height: 100%;
	padding-top: 10px;
	// Margin to account for the fixed header height
	margin: 70px auto 0;
	overflow-x: hidden;
	background: #151515;

	.sub-menu {
		padding: 0;
		margin-bottom: 0;
		text-align: center;
		background-color: #fff;
		border: 1px solid #dbdbdb;
		position: relative;
		z-index: 9;
		a {
			display: inline-block;
			padding: 10px 10px;
			font-family: 'O7TF Display';
			font-size: 14px;
			color: #000;
			text-align: center;
			&.router-link-active {
				font-family: 'O7TF Display';
				font-size: 15px;
				color: #ed3023;
			}
		}
	}

	.body {
		height: 100%;
		padding: env(safe-area-inset-top) 0 0 0 !important;
		margin: -2px auto 0;
		background: #151515;
	}
}
</style>
